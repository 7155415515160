<template>
  <div>
    <van-nav-bar left-arrow
                 :title="$t('tbm.公出申请')"
                 fixed
                 right-:text="$t('tbm.取消')"
                 @click-left="$router.go(-1)"
                 @click-right="$router.go(-1)">
    </van-nav-bar>
    <editComponent></editComponent>
  </div>
</template>
<script>
import editComponent from './components/editComponent';
export default {
  components: {
    editComponent
  },
  data () {
    return {

    }
  },
  beforeRouteLeave (to, from, next) {
    console.log(to.name, 8888888888888888)
    if (to.name == "approvalPanel") {
      next();
    } else {
      sessionStorage.removeItem('formData');
      next();
    }
  },
}
</script>
